import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

import { DecryptedDataAdmin, AdminDashboardGraph, EncryptDataAdmin, GetDecryptAccessTokenformat } from "../../Components/Common/Common";

const initialState = {
  email: "",
  googleSigninData: [],
  profileDetails: [],
  isLoading: false,
  isInsiteDataLoading: true,
  wordsData: [],
  InstagramUserDetails: "",
  InstagramProfileData: "",
  ImageCaptionGenerate: "",
  TokenID: "",
  HashedTokenID: "",
  S3BucketImage: "",
  getUserWalletDetails: "",
  walletBalanceUser: "",
  userPrivateKey: "",
  transactionHistory: [],
  AiAgentResponse: [],
  BillingCostOpenAI: {},
  BillingCostOpenAIAmount: [],
  hasInstaAccess: false,
  ServerDailyCost: {},
  StabilityDiffusion: {},
  userlimitdata: "",
  CostGraph: {},
  userRole: null,
  BillingCostGemini: "",
  profileDetailsAdmin: "",
  AllUserDetails: [],
  encyptData: "",
  UsageLLMUser: [],
  AwsCostUsage: {},
  ALLfaq: [],
  QrcodeDetails: {},
  GetQrcodeDetails: {},
  getuseruniqueId: "",
  userHistory: [],
  tokenExist: "",
  adminTransactionHistory: [],
  subscriptionPlans: [],
};

const location = window.location;

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    HandleEmaildata: (state, actions) => {
      state.email = actions?.payload;
    },
    HandleGoogleSigninData: (state, actions) => {
      state.googleSigninData = actions?.payload;
    },
    HandleStoreUserHistory: (state, actions) => {
      state.userHistory = actions?.payload;
    },
    HandleAdminTransactionHistory: (state, actions) => {
      state.adminTransactionHistory = actions?.payload;
    },
    HandleProfileDetails: (state, actions) => {
      state.profileDetails = actions?.payload;
    },
    HandleProfileDetailsAdmin: (state, actions) => {
      state.profileDetailsAdmin = actions?.payload;
    },
    HandleQrCode: (state, actions) => {
      state.QrcodeDetails = actions?.payload;
    },
    GetHandleQrCode: (state, actions) => {
      state.GetQrcodeDetails = actions?.payload;
    },
    SetLoading: (state, actions) => {
      state.isLoading = actions?.payload;
    },
    SetDataurl: (state, actions) => {
      state.Dataredirect = actions?.payload;
    },
    HandleAllFaq: (state, actions) => {
      state.ALLfaq = actions?.payload;
    },
    SetBillingCostOpenAI: (state, actions) => {
      state.BillingCostOpenAI = actions?.payload;
    },
    UpdateApiStatus: (state, actions) => {
      state.ApiStatus = actions?.payload;
    },
    SetBillingCostGemini: (state, actions) => {
      state.BillingCostGemini = actions?.payload;
    },
    HandleSetBillingCostOpenAI: (state, actions) => {
      state.BillingCostOpenAIAmount = actions?.payload;
    },
    HandleMaildata: (state, actions) => {
      state.wordsData = actions?.payload;
    },
    HandleAiagentdata: (state, actions) => {
      state.AiAgentResponse = actions?.payload;
    },
    HandleWalletDetails: (state, actions) => {
      state.getUserWalletDetails = actions?.payload;
    },
    HandleUserPrivatekeyDetails: (state, actions) => {
      state.userPrivateKey = actions?.payload;
    },
    HandleWalletBalance: (state, actions) => {
      state.walletBalanceUser = actions?.payload;
    },

    HandleInstagramUserData: (state, actions) => {
      state.InstagramUserDetails = actions?.payload;
    },

    HandleUpdateHasInstaAccess: (state, actions) => {
      state.hasInstaAccess = actions?.payload;
    },
    HandleUpdateUserRole: (state, actions) => {
      state.userRole = actions?.payload;
    },

    HandleTransactionHistory: (state, actions) => {
      state.transactionHistory = actions?.payload;
    },

    HandleInstagramUserProfileData: (state, actions) => {
      state.InstagramProfileData = actions?.payload;
    },
    HandleImageCaptionGenerate: (state, actions) => {
      state.ImageCaptionGenerate = actions?.payload;
    },

    HandleTokenIdGenerate: (state, actions) => {
      state.TokenID = actions?.payload;
    },
    HandleHashedTokenId: (state, actions) => {
      state.HashedTokenID = actions?.payload;
    },
    HandleS3BucketImage: (state, actions) => {
      state.S3BucketImage = actions?.payload;
    },
    SetInsiteLoading: (state, actions) => {
      state.isInsiteDataLoading = actions?.payload;
    },
    HandleServerDailyCost: (state, action) => {
      state.ServerDailyCost = action?.payload;
    },
    HandleStabilityDiffusionCost: (state, action) => {
      state.StabilityDiffusion = action?.payload;
    },
    HandleUserlimit: (state, action) => {
      state.userlimitdata = action?.payload;
    },
    HandlegetServerCostGraph: (state, action) => {
      state.CostGraph = action?.payload;
    },
    HandleGetOpenAiLimit: (state, action) => {
      state.OpenAiLimit = action?.payload;
    },
    HandlegetOpenAiCostGraph: (state, action) => {
      state.CostGraphOpenAi = action?.payload;
    },
    HandleGetUserStabilityDiffusionLimitSet: (state, action) => {
      state.StabilityLimit = action?.payload;
    },
    HandlegetStabilityCostGraph: (state, action) => {
      state.CostGraphStability = action?.payload;
    },
    HandleGeminiLimit: (state, action) => {
      state.GeminiLimit = action?.payload;
    },
    HandlegetGeminiCostGraph: (state, action) => {
      state.CostGraphGemini = action?.payload;
    },
    HandleAwsDetailsData: (state, action) => {
      state.AwsCostUsage = action?.payload;
    },
    UpdateBillingCost: (state, action) => {
      state.BillingCostLLM = action?.payload;
    },
    HandleAllUserDetails: (state, action) => {
      state.AllUserDetails = action?.payload;
    },
    HandleUpdateEncypt: (state, action) => {
      state.encyptData = action?.payload;
    },
    UpdateCost: (state, action) => {
      state.DashboardCardCost = action?.payload;
    },
    AdminDashboard: (state, action) => {
      state.DashboardGraph = action?.payload;
    },
    HandleLLMUserData: (state, action) => {
      state.UsageLLMUser = action?.payload;
    },
    GetHandleQrCodeUniqueId: (state, action) => {
      state.getuseruniqueId = action?.payload;
    },
    GetTiktokUserData: (state, action) => {
      state.gettiktokuserdetails = action?.payload;
    },
    GetTokenExist: (state, action) => {
      state.tokenExist = action?.payload;
    },
    HandleSubscriptionPlans: (state, action) => {
      state.subscriptionPlans = action.payload;
    },
  },
});

export const {
  HandleEmaildata,
  UpdateCost,
  GetTokenExist,
  GetTiktokUserData,
  AdminDashboard,
  HandleUpdateEncypt,
  HandleAllUserDetails,
  HandleS3BucketImage,
  HandleGoogleSigninData,
  HandleProfileDetails,
  SetLoading,
  HandleMaildata,
  HandleInstagramUserData,
  HandleHashedTokenId,
  HandleTokenIdGenerate,
  HandleWalletBalance,
  HandleStoreUserHistory,
  HandleWalletDetails,
  HandleUpdateUserRole,
  HandleTransactionHistory,
  HandleUserPrivatekeyDetails,
  SetBillingCostOpenAI,
  HandleSetBillingCostOpenAI,
  HandleStabilityDiffusionCost,
  HandleUserlimit,
  HandlegetServerCostGraph,
  SetBillingCostGemini,
  HandleGetOpenAiLimit,
  HandlegetOpenAiCostGraph,
  HandleGetUserStabilityDiffusionLimitSet,
  HandlegetStabilityCostGraph,
  HandleGeminiLimit,
  HandlegetGeminiCostGraph,
  SetLLMError,
  HandleProfileDetailsAdmin,
  HandleLLMUserData,
  HandleAwsDetailsData,
  HandleAllFaq,
  HandleQrCode,
  GetHandleQrCode,
  GetHandleQrCodeUniqueId,
  HandleSubscriptionPlans,
} = globalSlice.actions;

export default globalSlice.reducer;

export function ReGenerateTokenAdmin(ApiFunction, body, dispatch) {
  const token = localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/adminRefreshToken`;
      const data = {
        token: localStorage?.getItem("refresh_token"),
      };
      const response = await axios.post(url, data, reqoptions);

      if (response?.data) {
        SetLoading(false);

        localStorage.setItem("access_token", response.data.token);
        if (ApiFunction) {
          dispatch(ApiFunction(body));
        }
      }
    } catch (error) {
      SetLoading(false);
    } finally {
      SetLoading(false);
    }
  };
}

export function OpenAPIBillingCost() {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_Chat`;
      const response = await axios.get(url, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.SetBillingCostOpenAI(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(OpenAPIBillingCost, {}, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetApiStatus({ dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));

      const urls = [
        `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getServerDailyCost1`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_Chat`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_sd_image`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_cost`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/image_generation`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/llama_Status`,
      ];

      const requests = urls.map((url) => axios.get(url, reqOptions).catch((error) => ({ error })));

      const responses = await Promise.all(requests);

      const result = responses.map((response, index) => {
        if (response.error) {
          return {
            name: getApiNameByIndex(index),
            status: "Deactive",
            total_cost: null,
            statuscode: response.error.response?.status || null,
          };
        }

        switch (index) {
          case 0:
            return {
              name: "AWS",
              status: response.data.success ? "success" : "failed",
              total_cost: parseFloat(response.data.data),
              status: "Active",
              statuscode: response?.status,
            };

          case 1:
            return {
              name: "OpenAI",
              status: response.data.status,
              total_cost: response.data.total_cost,
              status: "Active",
              statuscode: response?.status,
            };
          case 2:
            return {
              name: "Stablity",
              status: response.data.status,
              total_cost: response.data.total_cost_in_dollar,
              status: "Active",
              statuscode: response?.status,
            };

          case 3:
            return {
              name: "GeminiAI",
              status: response.data.status === 200 ? "success" : "failed",
              total_cost: response.data.total_cost,
              status: "Active",
              statuscode: response?.status,
            };
          case 4:
            return {
              name: "Flux",
              status: response.data.status,
              total_cost: "30",
              status: "Active",
              statuscode: response?.status,
            };
          case 5:
            return {
              name: "Llama 3.2",
              status: response.data.status,
              total_cost: "30",
              status: "Active",
              statuscode: response?.status,
            };
          default:
            return {
              name: `unknown_response_${index}`,
              status: "unknown",
              total_cost: 0,
            };
        }
      });

      dispatch(globalSlice?.actions?.UpdateApiStatus(result));
      dispatch(SetLoading(false));
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetApiStatus, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

const getApiNameByIndex = (index) => {
  switch (index) {
    case 0:
      return "AWS";
    case 1:
      return "OpenAI";
    case 2:
      return "Stability";
    case 3:
      return "GeminiAI";
    case 4:
      return "Flux";
    case 5:
      return "Llama 3.2";
    default:
      return `unknown_response_${index}`;
  }
};

export function GetServerDailyCost() {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getServerDailyCost1`;
      const response = await axios.get(url, reqoptions);
      if (response?.data?.success === true) {
        const blendedCost = response?.data?.data;
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleServerDailyCost(blendedCost));
        return response?.data;
      } else {
        console.error("API call was not successful:", response?.data?.message);
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(GetServerDailyCost, {}, dispatch));
        return;
      }
      console.error(error?.response?.data?.message || "An error occurred", "errrrrrrrr");
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function StabilityDiffusionCost() {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_sd_image`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleStabilityDiffusionCost(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(StabilityDiffusionCost, {}, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GeminiBillingCost() {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_cost`;
      const response = await axios.get(url, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.SetBillingCostGemini(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(OpenAPIBillingCost, {}, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function SetUserLimit({ obj }) {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/userLimitSet`;

      const response = await axios.post(url, body, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(GetUserLimit({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(SetUserLimit, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetUserLimit({ body }) {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getUserLimitSet/${body?.user_id}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleUserlimit(response.data.data.limit));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateTokenAdmin(GetUserLimit, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getServerCostGraph({ currentYearAWS, currentYear }) {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getServerCostGraph/${currentYearAWS || currentYear}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandlegetServerCostGraph(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getServerCostGraph, { currentYearAWS }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function SetOpenAiLimit({ obj }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/userOpenAiLimitSet `;

      const response = await axios.post(url, body, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(GetOpenAiLimit({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(SetOpenAiLimit, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetOpenAiLimit({ body }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getUserOpenAiLimitSet/${body?.user_id}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleGetOpenAiLimit(response.data.data.limit));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateTokenAdmin(GetOpenAiLimit, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getOpenAiCostGraph({ currentYearOpenAI }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getOpenAiCostGraph/${currentYearOpenAI}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandlegetOpenAiCostGraph(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getOpenAiCostGraph, { currentYearOpenAI }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function setuserStabilityDiffusionLimitSet({ obj }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/userStabilityDiffusionLimitSet `;

      const response = await axios.post(url, body, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(getUserStabilityDiffusionLimitSet({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(setuserStabilityDiffusionLimitSet, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getUserStabilityDiffusionLimitSet({ body }) {
  const tokenAdmin = localStorage?.getItem("access_token");
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getUserStabilityDiffusionLimitSet/${body?.user_id}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleGetUserStabilityDiffusionLimitSet(response.data.data.limit));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateTokenAdmin(getUserStabilityDiffusionLimitSet, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getStabilityCostGraph({ currentYearStability }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getStabilityCostGraph/${currentYearStability}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandlegetStabilityCostGraph(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getStabilityCostGraph, { currentYearStability }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function SetGeminiLimit({ obj }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };

  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/userGeminiLimitSet `;

      const response = await axios.post(url, body, reqoptions);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(GetGeminiLimit({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(SetGeminiLimit, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetGeminiLimit({ body }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getUserGeminiLimitSet/${body?.user_id}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleGeminiLimit(response.data.data.limit));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateTokenAdmin(GetGeminiLimit, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function getGeminiCostGraph({ currentYearGemini }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getGeminiCostGraph/${currentYearGemini}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandlegetGeminiCostGraph(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getGeminiCostGraph, { currentYearGemini }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetbillingStatusDATA({ dispatch, fromAndToDate }) {
  const token = GetDecryptAccessTokenformat() || localStorage.getItem("access_token");
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));

      let urls = [];

      if (fromAndToDate[0] && fromAndToDate[1]) {
        urls = [
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/openai_daterange_cost?start_date=${fromAndToDate[0]}&end_date=${fromAndToDate[1]}`,
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/sd_daterange_cost?start_date=${fromAndToDate[0]}&end_date=${fromAndToDate[1]}`,
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_daterange_cost?start_date=${fromAndToDate[0]}&end_date=${fromAndToDate[1]}`,
          `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/serverCostDateWise?start_date=${fromAndToDate[0]}&end_date=${fromAndToDate[1]}`,
        ];
      } else {
        urls = [
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/openai_daterange_cost`,
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/sd_daterange_cost`,
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_daterange_cost`,
          `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/serverCostDateWise`,
        ];
      }

      const requests = urls.map((url) => axios.get(url, reqOptions));
      const responses = await Promise.all(requests);

      const result = responses.map((response, index) => {
        const isDateRangeSelected = fromAndToDate[0] && fromAndToDate[1];

        switch (index) {
          case 0:
            return {
              name: "OpenAI",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
              month: isDateRangeSelected ? "" : new Date().toLocaleString("default", { month: "long" }),
              startmonth: response?.data?.start_month,
              endmonth: response?.data?.end_month,
              year: isDateRangeSelected ? "" : new Date().getFullYear(),
              startyear: response?.data?.start_year,
              endyear: response?.data?.end_year,
            };
          case 1:
            return {
              name: "Stability",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };
          case 2:
            return {
              name: "Gemini",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };
          case 3:
            return {
              name: "AWS",
              status: response.data.status,
              total_cost: response.data.total_cost ? `$${response.data.total_cost}` : "$ 0.0000",
              statuscode: response?.status,
              month: isDateRangeSelected ? "" : response?.data?.month_year?.month,
              startmonth: response?.data?.month_year?.start_month,
              endmonth: response?.data?.month_year?.end_month,
              year: isDateRangeSelected ? "" : response?.data?.month_year?.year,
              startyear: response?.data?.month_year?.start_year,
              endyear: response?.data?.month_year?.end_year,
            };
          default:
            return {
              name: `unknown_response_${index}`,
              status: "unknown",
              total_cost: 0,
            };
        }
      });

      const parseCost = (costString) => parseFloat(costString.replace("$", "")) || 0;
      const totalCostSum = result.reduce((acc, curr) => acc + parseCost(curr.total_cost), 0);

      result.push({
        name: "Total",
        status: "N/A",
        total_cost: `$${totalCostSum.toFixed(4)}`,
        statuscode: "N/A",
      });

      dispatch(globalSlice?.actions?.UpdateBillingCost(result));
      dispatch(SetLoading(false));
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetApiStatus, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

// *******************changed added today*************************
export function getServerCostDataAws({ dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token || tokenAdmin}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/serverCostDateWise`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleAwsDetailsData(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(getServerCostDataAws, { dispatch }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

// ************************************

export function OpenAIandGeminiUserDataSave({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/saveUserOpenAiGeminiUsageCost`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.data?.message);
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(OpenAIandGeminiUserDataSave, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}
//  *********************************
export function GetOpenAIandGeminiUserDataSave({ dispatch, currentYearUserReport, currentMonthDataReport }) {
  const tokenAdmin = localStorage?.getItem("access_token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${tokenAdmin}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/getAllUserOpenAiGeaminiUsageCost?year=${currentYearUserReport}&month=${currentMonthDataReport}`;

      const response = await axios.get(url, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleLLMUserData(response.data.data));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetOpenAIandGeminiUserDataSave, bodydata, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

// *******************************ADMIN API**********************************************

export function AdminLogin({ navigate, objData }) {
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/signIn`;

      const response = await axios.post(url, objData);

      if (response?.data.success === true) {
        let EncryptedDataResponse = response.data.data;
        const result = DecryptedDataAdmin(EncryptedDataResponse);
        let email = EncryptDataAdmin(result.data.email);
        let reference_number = EncryptDataAdmin(result?.data?.reference_number);
        let username = EncryptDataAdmin(result?.data?.username);
        localStorage.setItem("email", email);
        localStorage.setItem("reference_number", reference_number);
        localStorage.setItem("username", username);
        localStorage.setItem("access_token", result?.token);
        localStorage.setItem("refresh_token", result?.refresh_token);
        localStorage.setItem("userid", result?.data?._id);
        dispatch(globalSlice?.actions?.HandleUpdateUserRole(result?.data?.role));
        toast.success(response?.data?.message);
        dispatch(HandleUpdateUserRole(response?.data?.data?.role));
        dispatch(HandleUpdateEncypt(response.data.encryptedData));
        navigate("/admindashboard");
        dispatch(SetLoading(false));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { objData, dispatch };
        dispatch(ReGenerateTokenAdmin(AdminLogin, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetProfileAdmin({ objData, dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getAdminProfile`;
      const response = await axios.post(url, objData, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));

        dispatch(globalSlice?.actions?.HandleUpdateUserRole("admin"));

        let EncryptedDataResponse = response.data.data;
        const result = DecryptedDataAdmin(EncryptedDataResponse);

        dispatch(globalSlice?.actions?.HandleProfileDetailsAdmin(result));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { objData, dispatch };
        dispatch(ReGenerateTokenAdmin(GetProfileAdmin, body, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}
export function GetAllUserDetails({ dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getAllUsersList`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        let EncryptedDataResponse = response.data.data;
        const result = DecryptedDataAdmin(EncryptedDataResponse);
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleAllUserDetails(result?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetAllUserDetails, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UploadProfilePictureAdmin({ objforupdatepic, dispatch, objData }) {
  const token = localStorage?.getItem("access_token");
  const formData = new FormData();
  formData.append("userId", objforupdatepic?.userId);
  formData.append("profile_image", objforupdatepic?.profile_image);

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/uploadImg`;
      const response = await axios.post(url, formData, reqoptions);

      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetProfileAdmin({ dispatch, objData }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        const body = { objforupdatepic, dispatch, objData };
        dispatch(ReGenerateTokenAdmin(UploadProfilePictureAdmin, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function adminDashboardCost({ dispatch, carddate }) {
  const token = localStorage.getItem("access_token");
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let urls = [];
      urls = [
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/openai_daterange_cost?start_date=${carddate}&end_date=${carddate}`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/sd_daterange_cost?start_date=${carddate}&end_date=${carddate}`,
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/gemini_daterange_cost?start_date=${carddate}&end_date=${carddate}`,
        `${process.env.REACT_APP_apiBaseUrl}/billingStats/api/v1/serverCostDateWise?start_date=${carddate}&end_date=${carddate}`,
      ];
      const requests = urls.map((url) => axios.get(url, reqOptions));
      const responses = await Promise.all(requests);

      const result = responses.map((response, index) => {
        switch (index) {
          case 0:
            return {
              name: "OpenAI",
              status: response.data.success,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };

          case 1:
            return {
              name: "Stability",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };
          case 2:
            return {
              name: "Gemini",
              status: response.data.status,
              total_cost: response.data.total_cost,
              statuscode: response?.status,
            };
          case 3:
            return {
              name: "AWS",
              status: response.data.status,
              total_cost: response.data.total_cost ? `$${response.data.total_cost}` : "$ 0.0000",
              statuscode: response?.status,
            };

          default:
            return {
              name: `unknown_response_${index}`,
              status: "unknown",
              total_cost: 0,
            };
        }
      });
      const parseCost = (costString) => parseFloat(costString.replace("$", "")) || 0;
      const totalCostSum = result.reduce((acc, curr) => acc + parseCost(curr.total_cost), 0);

      result.push({
        name: "Total",
        status: "N/A",
        total_cost: `$${totalCostSum.toFixed(4)}`,
        statuscode: "N/A",
      });

      dispatch(globalSlice?.actions?.UpdateCost(result));
      dispatch(SetLoading(false));
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(adminDashboardCost, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function AdminRegisterUersDetails({ dispatch, body }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getUserRegistrationsByMonth?year=${body?.changeyear}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        let EncryptedDataResponse = response.data.data;
        const result = AdminDashboardGraph(EncryptedDataResponse);
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.AdminDashboard(result));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(AdminRegisterUersDetails, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function AdminLogout({ objData, dispatch, navigate }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/adminLogout`;
      const response = await axios.post(url, objData, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        navigate("/");
        localStorage.clear();
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { objData, dispatch };
        dispatch(ReGenerateTokenAdmin(GetProfileAdmin, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function CreateFaqApi({ obj, dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/createFaq`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetFaqAPI({ dispatch }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(CreateFaqApi, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function GetFaqAPI({ dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/getAllFaq`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.HandleAllFaq(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateTokenAdmin(GetFaqAPI, body, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function DeleteFaqAPI({ dispatch, obj }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/deletefaq`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetFaqAPI({ dispatch }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(DeleteFaqAPI, body, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UpdateFaqApi({ obj, dispatch }) {
  const token = localStorage?.getItem("access_token");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/admin/api/v1/updateFaq`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetFaqAPI({ dispatch }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateTokenAdmin(CreateFaqApi, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

// Subscription APIs

export function GetSubscriptionPlans({ dispatch }) {
  const token = localStorage.getItem("access_token");
  const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
  const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
  const basicAuthCredentials = btoa(`${username}:${password}`);

  const reqOptions = {
    headers: {
      // Authorization: `Bearer ${token}`,
      // "Authorization-Basic": `Basic ${basicAuthCredentials}`,
      Authorization: `Basic ${basicAuthCredentials}`,
      "Authorization-Bearer": `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/getSubscriptionTier?user=admin`;
      const response = await axios.get(url, reqOptions);

      if (response?.data?.success) {
        dispatch(SetLoading(false));
        dispatch(globalSlice.actions.HandleSubscriptionPlans(response.data.data));
        return response.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(GetSubscriptionPlans, {}, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function CreateSubscriptionPlan({ obj, dispatch }) {
  const token = localStorage.getItem("access_token");
  const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
  const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
  const basicAuthCredentials = btoa(`${username}:${password}`);

  const reqOptions = {
    headers: {
      Authorization: `Basic ${basicAuthCredentials}`,
      "Authorization-Bearer": `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/createSubscriptionTier`;
      const response = await axios.post(url, obj, reqOptions);

      if (response?.data?.success) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        dispatch(GetSubscriptionPlans({ dispatch }));
        return response.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(CreateSubscriptionPlan, { obj, dispatch }, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function UpdateSubscriptionPlan({ obj, dispatch }) {
  const token = localStorage.getItem("access_token");
  const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
  const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
  const basicAuthCredentials = btoa(`${username}:${password}`);

  const reqOptions = {
    headers: {
      Authorization: `Basic ${basicAuthCredentials}`,
      "Authorization-Bearer": `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/updateSubscriptionTier`;
      const response = await axios.patch(url, obj, reqOptions);

      if (response?.data?.success) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        dispatch(GetSubscriptionPlans({ dispatch }));
        return response.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(UpdateSubscriptionPlan, { obj, dispatch }, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}

export function DeleteSubscriptionPlan({ subscriptionTierReferenceNumber, dispatch }) {
  const token = localStorage.getItem("access_token");
  const username = process.env.REACT_APP_BASIC_AUTH_USERNAME;
  const password = process.env.REACT_APP_BASIC_AUTH_PASSWORD;
  const basicAuthCredentials = btoa(`${username}:${password}`);

  const reqOptions = {
    headers: {
      Authorization: `Basic ${basicAuthCredentials}`,
      "Authorization-Bearer": `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/deleteSubscriptionTier/${subscriptionTierReferenceNumber}`;
      const response = await axios.delete(url, reqOptions);

      if (response?.data?.success) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        dispatch(GetSubscriptionPlans({ dispatch }));
        return response.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateTokenAdmin(DeleteSubscriptionPlan, { subscriptionTierReferenceNumber, dispatch }, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      dispatch(SetLoading(false));
    }
  };
}
